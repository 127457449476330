.brucia__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brucia__links-logo{
    height: 105px;
    width: 105px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 3rem;
}

.brucia__links-menu{
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    position: absolute;
    right: 90px;
    top: 33px;
    width: auto;
}

.brucia__links-menu p a{
    color: #000000;
    text-decoration: none;
    font-family: var(--font-family-heading);
    font-size: 18px;
    margin-right: 55px;
}

.brucia__links-menu::after{
    content: '';
    height: 1px;
    width: 455px;
    background: var(--red);
    position: absolute;
    top: 40px;
    left: 1px;
}

.brucia__links-menu p a:hover{
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.brucia__navbar-mobile-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}

.brucia__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: #f1f1f1;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.brucia__navbar-menu_container p{
    margin: 1rem 0;
}

.brucia__navbar-menu_container-links p a{
    text-decoration: none;
    color: #000000;
    font-family: var(--font-family-heading);
}

@media screen and (max-width: 1050px) {
    .brucia__links-menu p a{
        font-size: 16px;
    }
}

@media screen and (max-width: 800px) {
    .brucia__navbar{
        padding: 2rem 4rem;
    }

    .brucia__links-menu{
        display: none;
    }

    .brucia__navbar-mobile-menu{
        display: block;
        position: fixed;
        right: 30px;
    }

    .brucia__navbar-mobile-menu svg{
        height: 40px;
        width: 40px;
    }

    .brucia__links-logo{
        height: 100px;
        width: 100px;
    }
}

@media screen and (max-width: 550px) {
    .brucia__navbar-mobile-menu svg{
        height: 50px;
        width: 50px;
        top: 20px;
        position: fixed;
        right: 20px;
    }
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
}

@keyframes scale-up-center {
0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
}